import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Icon } from "../Icon";

export const CertificateStatusLabels = {
  awaiting_validation: 'Awaiting Validation (Full)',
  error: "Error",
  revoked: 'Revoked',
  rejected: 'Rejected',
  valid: 'Valid',
  expired: 'Expired'

};

const CertificateIcon = {
  awaiting: <Icon name="schedule" />,
  valid: <Icon name="done_all" />,
  revoked: <Icon name="error_outline" />,
};

export const CertificateStatus = (props) => (
  <div className={classnames('scan-status', props.status)} title={props.message}>
    <span>{CertificateStatusLabels[props.status] || '-'}</span>
    {CertificateIcon[props.status] || ''}
  </div>
);

CertificateStatus.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
};
