import PropTypes from 'prop-types'
import classnames from 'classnames'

export const CsrField = ({ label, input, error, disabled }) => (
  <div className="field">
    <label htmlFor="tipo" style={{ textAlign: 'center' }}>
      {label} *
    </label>

    <textarea
      {...input}
      rows="17"
      className={classnames('textarea', 'certificate', {
        'input-error': !!error,
      })}
      disabled={disabled}
    />

    {error &&
      <span className="input-error">
        {error}
      </span>
    }
  </div>
)

CsrField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
}
