import {
    ApplicationApiDetails,
    ApplicationDetails,
    Applications,
    Certificates,
    Company,
    Devices,
    Domains,
    DomainValidation,
    EmailNotifications,
    EvidenceResponse,
    FalsePositive,
    FullReport,
    GenericIndex,
    HowToActivateSSL,
    IndexArmour,
    IndexCertificates,
    IndexDevices,
    IndexManuals,
    IndexNotifications,
    IndexOrganizations,
    IndexRescue,
    IndexSettings,
    IndexStore,
    IndexSystem,
    InvitationAccepted,
    ListManuals,
    MyProfile,
    NewArmour,
    NewCertificate,
    NewDomain,
    NewScanApi,
    NewServer,
    NotFound,
    Orders,
    OrderSuccess,
    Organization,
    Organizations,
    OrganizationUsers,
    Product,
    Products,
    ProductsPermissions,
    RequestCertificate,
    Rescue,
    ScanApi,
    ScanDetails,
    Security,
    Security2FA,
    Server,
    Servers,
    SSLNoteManual,
    User,
    UsersOrganizations,
    Validation,
    Waf
} from "../views";

import { IndexPentest } from "../views/PenTest";
import PenTestEdit from "../views/PenTest/Edit";
import PenTestForm from "../views/PenTest/Form";
import PenTestList from "../views/PenTest/List";
import PentestDashboard from "../views/PenTest/Dashboard";
import PentestKnowledgebaseList from "../views/system/PentestKnowledgebase/List";
import PentestKnowledgebaseForm from "../views/system/PentestKnowledgebase/Form";
import { UsersList } from "../containers";
import PentestLandingPage from "../views/PenTest/List/PentestLandingPage";
import { EditOrganizations } from "../views/organizations/EditOrganizations";
import { EditUser } from "../views/users/EditUser";
import { onlyAdmin, ownerView } from "../services/auth";
import { BlockedPage } from "../views/BlockedPage";
import { PentestSystem } from "../views/system/PentestSystem";
import { IndexBackoffice } from "../views/backoffice";
import BackofficeSalesforce from "../views/backoffice/Salesforce";
import Documents from "../views/certificates/Documents";
import CsrValidate from "../views/certificates/CsrValidate";
import CsrGenerate from "../views/certificates/CsrGenerate";
import { IndexDashboard } from "../views/dashboard/IndexDashboard";

const privateRoutes = [
    {
        name: "Código Promocional",
        component: IndexRescue,
        path: "/rescue",
        routes: [
            {
                name: "Recuperar Código Promocional",
                path: "/rescue/:id",
                exact: true,
                component: Rescue,
            },
        ],
    },
    {
        name: "Domínios",
        component: IndexDevices,
        path: "/devices",
        redirect: "/devices",
        routes: [
            {
                name: "Meus Domínios",
                path: "/devices/domains",
                exact: true,
                component: Devices,
            },
            {
                name: "Meus Domínios",
                path: "/devices/domains/:id/validation/email",
                exact: true,
                component: Validation,
            },
            {
                name: "Adicionar Domínio",
                path: "/devices/domains/new",
                exact: true,
                component: NewDomain, //BlockedForUser
            },
            {
                name: "Meus servidores",
                path: "/devices/servers",
                exact: true,
                component: Servers,
            },
            {
                name: "Adicionar servidor",
                path: "/devices/servers/new",
                exact: true,
                component: NewServer,
            },
            {
                name: "Editar servidor",
                path: "/devices/servers/:id",
                exact: true,
                component: Server,
            },
            {
                name: "Validação de Domínio",
                path: "/devices/validation/:id",
                exact: true,
                component: DomainValidation, //BlockedForUser
            },
        ],
    },
    {
        name: "Loja Site Blindado",
        component: IndexStore,
        path: "/store",
        redirect: "/store/site-blindado",
        routes: [
            {
                name: "Solicitação Efetuada",
                path: "/store/success",
                exact: true,
                component: OrderSuccess,
            },
            {
                name: "Meus Produtos",
                path: "/store/products",
                exact: true,
                component: Products,
            },
            {
                name: "Meus Produto",
                path: "/store/product/:id",
                exact: true,
                component: Product,
            },
            {
                name: "Meus Pedidos",
                path: "/store/orders",
                exact: true,
                component: Orders,
            },
            {
                name: "Solicitar Certificado",
                path: "/store/:catalog",
                component: RequestCertificate, //BlockedForUser
            },
        ],
    },
    {
        name: "Manuais Site Blindado",
        component: IndexManuals,
        path: "/manuals",
        redirect: "/manuals",
        routes: [
            {
                name: "Listagem de Manuais",
                path: "/manuals/list",
                exact: true,
                component: ListManuals
            },
            {
                name: "Como Ativar um Certificado",
                path: "/manuals/how-to-activate-ssl",
                exact: true,
                component: HowToActivateSSL
            },
            {
                name: "Manual da Nota - SSL",
                path: "/manuals/ssl-report",
                exact: true,
                component: SSLNoteManual
            },
        ],
    },
    {
        name: "Certificados SSL",
        component: IndexCertificates,
        path: "/certificates",
        redirect: "/certificates",
        routes: [
            {
                name: "Meus domínios",
                path: "/certificates/domains",
                component: Domains,
            },
            {
                name: "Meus domínios",
                path: "/certificates/orders",
                exact: true,
                component: Certificates,
            },
            {
                name: "Emitir Certificado",
                path: "/certificates/new",
                exact: true,
                component: NewCertificate, //BlockedForUser
            },
            {
                name: "Gerar CSR",
                path: "/certificates/generate-csr",
                exact: true,
                component: CsrGenerate,
            },
            {
                name: "Validar CSR",
                path: "/certificates/validate-csr",
                exact: true,
                component: CsrValidate,
            },
            {
                name: "Documentos",
                path: "/certificates/docs",
                exact: true,
                component: Documents,
            },
            {
                name: "Editar Certificado",
                path: "/certificates/renew/:orderId",
                exact: true,
                component: NewCertificate, //BlockedForUser
            },
            {
                name: "Relatório SSL",
                path: "/certificates/report/:scanId",
                exact: true,
                component: FullReport,
            },
        ],
    },
    {
        name: "Blindagem de sites",
        component: IndexArmour,
        path: "/armour",
        redirect: "/armour/applications",
        routes: [
            {
                name: "Aplicações",
                path: "/armour/applications",
                exact: true,
                component: Applications,
            },
            {
                name: "Criar Aplicação",
                path: "/armour/new",
                exact: true,
                component: NewArmour,
            },
            {
                name: "Detalhes da Aplicação",
                path: "/armour/applications/:id",
                exact: true,
                component: ApplicationDetails,
            },
            {
                name: "Detalhes do Scan",
                path: "/armour/scan-details/:id",
                exact: true,
                component: ScanDetails,
            },
            {
                name: "",
                path: "/armour/scan-details/:scanId/http-response/:evidenceId",
                exact: true,
                component: EvidenceResponse,
            },
            {
                name: "",
                path: "/armour/scan-details/:scanId/false-positive/:evidenceId",
                exact: true,
                component: FalsePositive,
            },
            {
                name: "Scan de API",
                path: "/armour/scan-api",
                exact: true,
                component: ScanApi,
            },
            {
                name: "Criar scan de API",
                path: "/armour/scan-api/new",
                exact: true,
                component: NewScanApi,
            },
            {
                name: "Detalhes do scan de API",
                path: "/armour/scan-api/:id",
                exact: true,
                component: ApplicationApiDetails,
            },
        ],
    },
    // {
    //   name: 'Uptime',
    //   component: IndexUptime,
    //   path: '/uptime',
    //   redirect: '/uptime/urls',
    //   routes: [
    //     {
    //       name: 'Minhas Urls',
    //       path: '/uptime/urls',
    //       exact: true,
    //       component: Uptime,
    //     },
    //     {
    //       name: 'Ativar Uptime',
    //       path: '/uptime/new',
    //       exact: true,
    //       component: NewUptime, //BlockedForUser
    //     },
    //   ]
    // },
    {
        name: "Minhas Organizações",
        component: IndexOrganizations,
        path: "/organizations",
        routes: [
            {
                name: "Minhas Organizações",
                path: "/organizations",
                exact: true,
                component: Organizations,
            },
            // {
            //     name: "Criar Organização",
            //     path: "/organizations/new",
            //     exact: true,
            //     component: NewOrganization,
            // },
            {
                name: "Editar Organização",
                path: "/organizations/:id",
                component: EditOrganizations,
                routes: [
                    {
                        name: "Detalhes da Organização",
                        path: "/organizations/:id",
                        exact: true,
                        component: Organization,
                    },
                    {
                        name: "Usuários da Organização",
                        path: "/organizations/:id/users",
                        exact: true,
                        component: OrganizationUsers,
                    },
                    {
                        name: "Notificações",
                        path: "/organizations/:id/notifications",
                        exact: true,
                        component: EmailNotifications,
                    },
                    {
                        name: "API de Certificados",
                        path: "/organizations/:id/products-role",
                        exact: true,
                        component: ProductsPermissions,
                    },
                ],
            },
        ],
    },
    {
        name: "Usuários do Sistema",
        component: GenericIndex,
        path: "/users",
        routes: [
            {
                name: "Usuários",
                path: "/users",
                exact: true,
                component: onlyAdmin(UsersList, NotFound),
            },
            {
                name: "Editar Usuário",
                path: "/users/:id",
                component: EditUser,
                routes: [
                    {
                        name: "Detalhes do Usuário",
                        path: "/users/:id",
                        exact: true,
                        component: onlyAdmin(User, NotFound),
                    },
                    {
                        name: "Organizações do Usuário",
                        path: "/users/:id/organizations",
                        exact: true,
                        component: onlyAdmin(UsersOrganizations, NotFound),
                    },
                ],
            },
        ],
    },
    {
        name: "WAF",
        component: GenericIndex,
        path: "/waf",
        routes: [
            {
                name: "WAF",
                path: "/waf",
                exact: true,
                component: Waf, //requireUserAdmin
            },
        ],
    },
    {
        name: "Pentest",
        component: IndexPentest,
        path: "/pentest",
        redirect: "/pentest/list",
        routes: [
            {
                name: "Lista de pentests",
                path: "/pentest/list",
                exact: true,
                component: PenTestList,
            },
            {
                name: "Editar solicitação de Pentest",
                path: "/pentest/:id/edit",
                exact: true,
                component: onlyAdmin(PenTestForm, NotFound), //requireUserAdmin
            },
            {
                name: "Solicitação de Pentest",
                path: "/pentest/new",
                exact: true,
                component: onlyAdmin(PenTestForm, NotFound), //requireUserAdmin
            },
            {
                name: "Edição de Relatório",
                path: "/pentest/:id/report/:report_id",
                exact: true,
                component: onlyAdmin(PenTestEdit, NotFound), //requireUserAdmin
            },
            {
                name: "DashboardDashboard",
                path: "/pentest/:id/dashboard/:report_id",
                exact: true,
                component: ownerView(PentestDashboard, BlockedPage),
            },
            {
                name: "Landing Page - Pentest",
                path: "/pentest/landing-page",
                exact: true,
                component: PentestLandingPage,
            },
        ],
    },
    {
        name: "Convite Aceito",
        component: InvitationAccepted,
        path: "/invites/:id",
    },
    {
        name: "Configurações",
        component: IndexSettings,
        path: "/settings",
        redirect: "/settings/profile",
        routes: [
            {
                name: "Profile",
                path: "/settings/profile",
                exact: true,
                component: MyProfile,
            },
            {
                name: "Dados da Organização",
                path: "/settings/organization",
                exact: true,
                component: Company,
            },
            {
                name: "Senha",
                path: "/settings/security",
                exact: true,
                component: Security,
            },
            {
                name: "Segurança",
                path: "/settings/two-factor-auth",
                exact: true,
                component: Security2FA,
            },
        ],
    },
    {
        name: "Sistema",
        component: IndexSystem,
        path: "/system",
        redirect: "/system/vulnerabilities",
        routes: [
            {
                name: "Listagem - Pentests",
                path: "/system/pentest",
                component: PentestSystem,
                exact: true,
            },
            {
                name: "Vulnerabilidadades - Pentest",
                path: "/system/vulnerabilities-pentest",
                component: PentestKnowledgebaseList,
                exact: true,
            },
            {
                name: "Adicionar vulnerabilidade - Pentest",
                path: "/system/vulnerabilities-pentest/new",
                component: PentestKnowledgebaseForm,
                exact: true,
            },
            {
                name: "Editar vulnerabilidade - Pentest",
                path: "/system/vulnerabilities-pentest/edit/:id",
                component: PentestKnowledgebaseForm,
                exact: true,
            },
            {
                name: "Solicitação - Pentest",
                path: "/system/pentest/new",
                component: PenTestForm,
                exact: true,
            },
            // {
            //   name: 'Vulnerabilidades',
            //   path: '/system/vulnerabilities',
            //   component: Vulnerabilities, //requireUserAdmin
            // },
            // {
            //   name: 'Vulnerabilidades',
            //   path: '/system/vulnerabilities/:id',
            //   component: VulnerabilityTranslate, //requireUserAdmin
            // },
            // {
            //   name: 'Famílias de vulnerabilidades',
            //   path: '/system/families',
            //   component: Families, //requireUserAdmin
            // },
            // {
            //   name: 'Adicionar Família',
            //   path: '/system/families/new',
            //   component: NewFamily, //requireUserAdmin
            // },
            // {
            //   name: 'Família',
            //   path: '/system/families/:id',
            //   component: Family, //requireUserAdmin
            // },
        ],
    },
    {
        name: "Backoffice",
        component: IndexBackoffice,
        path: "/backoffice",
        redirect: "/backoffice/salesforce",
        routes: [
            {
                name: "Salesforce",
                path: "/backoffice/salesforce",
                exact: true,
                component: BackofficeSalesforce,
            },
            {
                name: "Editar solicitação de Pentest",
                path: "/backoffice/:id/edit",
                exact: true,
                component: onlyAdmin(PenTestForm, NotFound), //requireUserAdmin
            },
            {
                name: "Solicitação de Pentest",
                path: "/backoffice/new",
                exact: true,
                component: onlyAdmin(PenTestForm, NotFound), //requireUserAdmin
            },
            {
                name: "Edição de Relatório",
                path: "/backoffice/:id/report/:report_id",
                exact: true,
                component: onlyAdmin(PenTestEdit, NotFound), //requireUserAdmin
            },
            {
                name: "Dashboard",
                path: "/backoffice/:id/dashboard/:report_id",
                exact: true,
                component: ownerView(PentestDashboard, BlockedPage),
            },
            {
                name: "Landing Page - Pentest",
                path: "/backoffice/landing-page",
                exact: true,
                component: PentestLandingPage,
            },
        ],
    },
    {
        name: "Notificações",
        component: GenericIndex,
        path: "/notifications",
        routes: [
            {
                name: "Notificações",
                path: "/notifications",
                exact: true,
                component: IndexNotifications,
            },
        ],
    },
    {
        name: "Dashboard",
        component: GenericIndex,
        path: "/dashboard",
        routes: [
            {
                name: "Dashboard",
                path: "/dashboard",
                exact: true,
                component: IndexDashboard,
            },
        ],
    },
];

export default privateRoutes;
