import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit';
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { CsrField, SelectField, RadiosField, Loading, Button } from '../../../components'
import { CsrSubject, DomainsBoxList } from '../../../containers'
import { fetchServersOptions, getCsrInfo, clearCsrInfo } from '../../../actions'
import { addDomain, clearDomains } from '../../../actions/domains-box'
import { fetchProducts } from '../../../actions/products'
import { withAccountId } from '../../../containers/AccountContainer'
import _, { isEmpty } from 'lodash'
import loadingSvg from '../../../../assets/images/loading.svg';

class CsrForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { domainsLoaging: false, csrFieldError: '', productOptions: [] }
    this.getCsrInfo = this.getCsrInfo.bind(this)
    this.clearCsrInfo = this.clearCsrInfo.bind(this)
    this.handleEmissaoChange = this.handleEmissaoChange.bind(this);
  }

  componentDidMount () {
    if (!this.props.isLoading) {
      if (!this.props.renew) {
        const accountId = this.props.accountId
        this.props.fetchProducts(accountId, { page_size: 99997, category: 'ssl' })
      }
      this.props.fetchServersOptions()
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.props.accountId !== newProps.accountId) {
      this.props.fetchProducts(newProps.accountId, { page_size: 99998, category: 'ssl' })
    }
    if (
      this.props.formArmour.productId !== newProps.formArmour.productId
      && !this.props.selectedProduct
      && newProps.formArmour.productId
      && !_.isEmpty(this.props.productsOptions)
    ) {
      const product = this.props.productsOptions.filter(p => p.id === newProps.formArmour.productId)
      !!product[0] &&
        this.props.changeFieldValue('product', product[0].id)
    }
  }

  getCsrInfo (e) {
    e.preventDefault()

    const { csr } = this.props.dataForm.values

    csr
      ? this.props.getCsrInfo(csr)
        .then((res) => {
          const { value } = res

          if (value) {
            const listDomains = value.domains ? value.domains : {}

            const domains = _.isEmpty(listDomains)
              ? [value.subject.common_name]
              : [value.subject.common_name, ...listDomains]

            this.setState({ domainsLoaging: true, csrFieldError: '' })

            Promise.all(domains.map(domain => this.props.addDomain(domain)))
              .then(() => this.setState({ domainsLoaging: false }))
              .catch(() => this.setState({ domainsLoaging: false }))
          }
        })
        .catch(() => {
          this.setState({ csrFieldError: '' })
        })
      : this.setState({ csrFieldError: 'Campo obrigatório' })
  }

  isMultdomain () {
    const { selectedProduct } = this.props

    return !!selectedProduct && selectedProduct.product_type.is_multidomain
  }

  clearCsrInfo (e) {
    e.preventDefault()

    this.props.changeFieldValue('csr', '')
    this.props.clearCsrInfo()
    this.props.clearDomains()
  }

  handleEmissaoChange (value) {
    let options = []
    if (value === "pessoa_fisica") {
      options = this.props.productsOptions?.filter(
        option => !option.description.includes('EV')
      )
    } else {
      options = this.props.productsOptions
    }
    this.setState({ productOptions: options })
  }

  render () {
    const { nextStep, handleSubmit, csrInfo } = this.props
    const issetCsrInfo = !isEmpty(csrInfo) && !csrInfo.error && !this.state.csrFieldError

    return (
      <form className="form" onSubmit={handleSubmit(nextStep)}>
        <Field
          label="Emitir para:"
          name="emissao"
          component={RadiosField}
          items={[
            { label: 'Pessoa Física', value: 'pessoa_fisica' },
            { label: 'Pessoa Jurídica', value: 'pessoa_juridica' }
          ]}
          onChange={(event, value) => this.handleEmissaoChange(value)}
        />
        <Field
          label="Produto *"
          name="product"
          options={this.state.productOptions}
          emissao={this.props.emissao}
          component={inputProps =>
            <SelectField
              {...inputProps}
              selected={this.props.selectedProduct ? this.props.selectedProduct.id : inputProps.selected}
            />
          }
          error={this.props.errors.product || ''}
        />

        <Field
          label="Servidor *"
          name="server"
          options={this.props.serversOptions}
          component={SelectField}
          error={this.props.errors.server || ''}
        />

        {!issetCsrInfo &&
          <>
            <div className="field">
              <label htmlFor="tipo" style={{ textAlign: 'center' }}><strong>Caso não tenha uma Certificate Signing Request (CSR) <br /><a href='/certificates/generate-csr'>clique aqui</a> para gerar a sua!</strong></label>
            </div>
            <Field
              name="csr"
              label={`Se encontrar dificuldade em gerar sua CSR, entre em contato com o nosso suporte através do email
                            suporte@siteblindado.com.br`}
              component={CsrField}
              disabled={issetCsrInfo}
              error={csrInfo.error || this.state.csrFieldError}
            />
          </>
        }

        <Loading loading={csrInfo.isLoading || this.props.dataForm.loading} />

        {issetCsrInfo && !csrInfo.isLoading &&
          <div>
            <div className="field">
              <h1>Detalhes do CSR</h1>


              {this.isMultdomain() && this.state.domainsLoaging &&
                <p>
                  <img src={loadingSvg} alt="Loading" />
                  Aguarde. Extraíndo domínios do CSR.
                </p>
              }

              {this.isMultdomain() && !this.state.domainsLoaging &&
                <DomainsBoxList
                  maxDomains={this.props.selectedProduct.max_domains}
                />
              }

              <CsrSubject />
            </div>
          </div>
        }

        {issetCsrInfo && !csrInfo.isLoading &&
          <div className="field right">
            <Button
              pure
              onClick={this.clearCsrInfo}
            >
              Trocar CSR
            </Button>
          </div>
        }

        <div className="buttons">
          {!issetCsrInfo &&
            <Button
              onClick={this.getCsrInfo}
            >
              Validar CSR
            </Button>
          }

          {issetCsrInfo &&
            <Button
              disabled={!issetCsrInfo || this.state.domainsLoaging}
            >
              Próximo
            </Button>
          }
        </div>
      </form>

    )
  }
}

CsrForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  renew: PropTypes.bool,
  emissao: PropTypes.string,
  fetchServersOptions: PropTypes.func,
  addDomain: PropTypes.func,
  handleSubmit: PropTypes.func,
  getCsrInfo: PropTypes.func,
  nextStep: PropTypes.func,
  changeFieldValue: PropTypes.func,
  clearCsrInfo: PropTypes.func,
  clearDomains: PropTypes.func,
  csrInfo: PropTypes.object,
  dataForm: PropTypes.object,
  productsOptions: PropTypes.array,
  serversOptions: PropTypes.array,
  errors: PropTypes.object,
  selectedProduct: PropTypes.object,
  fetchProducts: PropTypes.func,
}

const selector = formValueSelector('formCertificates')

const mapStateToProps = (state) => {
  const { clientProducts, formArmour } = state

  const selectedProduct = clientProducts.results[selector(state, 'product')]
  const maxDomains = selectedProduct ? selectedProduct.max_domains : 1

  return {
    productsOptions: clientProducts.availablesIds.map(id => ({
      id: clientProducts.results[id].id,
      description: clientProducts.results[id].description,
    })),
    serversOptions: state.serversOptions,
    csrInfo: state.csrInfo,
    dataForm: state.form.formCertificates,
    maxDomains,
    selectedProduct,
    formArmour,
    isLoading: clientProducts.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change('formCertificates', field, value)),
  fetchServersOptions: bindActionCreators(fetchServersOptions, dispatch),
  getCsrInfo: bindActionCreators(getCsrInfo, dispatch),
  clearCsrInfo: bindActionCreators(clearCsrInfo, dispatch),
  clearDomains: bindActionCreators(clearDomains, dispatch),
  addDomain: bindActionCreators(addDomain, dispatch),
  fetchProducts: bindActionCreators(fetchProducts, dispatch),
})

const Csr = reduxForm({
  form: 'formCertificates',
  destroyOnUnmount: false,
  validate: (values) => {
    const errors = {}

    if (!values.product) {
      errors.product = 'Este campo é obrigatório'
    }

    if (!values.server) {
      errors.server = 'Este campo é obrigatório'
    }

    if (!values.csr) {
      errors.csr = 'Este campo é obrigatório'
    }

    return errors
  },
})(withAccountId(CsrForm))

export default connect(mapStateToProps, mapDispatchToProps)(Csr)
