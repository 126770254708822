import React, { memo, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Content } from "../../components";
import { useSelector } from 'react-redux'


export function IndexCertificates (props) {
  const [title, setTitle] = useState('Meus Domínios');
  const location = useLocation()
  const history = useHistory()

  const auth = useSelector(state => state.auth)
  const account = useSelector(state => state.account)

  const userRoutes = [
    { route: "/certificates/domains", name: "Meus Domínios" },
    { route: "/certificates/orders", name: "Gerenciador de Certificados" },
    { route: "/certificates/validate-csr", name: "Validar CSR" },
    { route: "/certificates/docs", name: "Documentos" },
  ];

  const adminRoutes = [
    { route: "/certificates/domains", name: "Meus Domínios" },
    { route: "/certificates/orders", name: "Gerenciador de Certificados" },
    { route: "/certificates/new", name: "Emitir Certificado" },
    { route: "/certificates/generate-csr", name: "Gerar CSR" },
    { route: "/certificates/validate-csr", name: "Validar CSR" },
    { route: "/certificates/docs", name: "Documentos" },
  ];

  const allowedRoutes = [
    "/certificates/domains",
    "/certificates/orders",
    "/certificates/new",
    "/certificates/generate-csr",
    "/certificates/validate-csr",
    "/certificates/docs",
  ];

  const haveAdminRole = auth.isAdmin || ['owner', 'admin'].includes(auth?.data?.organizations[account[0]?.id]?.role)

  const submenu = allowedRoutes.includes(location.pathname)
    ? haveAdminRole
      ? adminRoutes
      : userRoutes
    : [];

  useEffect(() => {
    if (location.pathname === '/certificates') {
      history.push('certificates/domains')
    }
  }, [])

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
