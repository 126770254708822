import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { TableWrap, TableWrapActions, TableWrapLeft, Dropdown, ValidationMethod, ContextMessage, Help } from '../../components'
import { RevokeContainer, SliderButton } from '../../containers'
import { Details, InstallStampBlindado, InstallStampTrustSign } from '../../views/certificates'
import { fetchOrders } from '../../actions'
import { withAccountId } from '../AccountContainer'
import { environment } from '../../../environments/environment'
import { userIsAdmin } from '../../services/auth'
import { MenuItem } from '@mui/material'
import { CertificateStatusLabels } from '../../components/certificates/CertificateStatus'

const SliderDetails = (props) => (
  <Details {...props} />
)

SliderDetails.propTypes = {
  order: PropTypes.number,
  common_name: PropTypes.string,
  product: PropTypes.string,
  status: PropTypes.string,
  server: PropTypes.string,
  details: PropTypes.object,
}

const RevokeOption = userIsAdmin(props => (
  <MenuItem>
    <SliderButton button="Revogar" sliderTitle="Revogar Certificado">
      <RevokeContainer order={props.order} />
    </SliderButton>
  </MenuItem>
))

const Actions = (props) => {
  const seals = {
    siteblindado: <InstallStampBlindado />,
    trustsign: <InstallStampTrustSign />,
  }

  if (props.is_revoked) {
    return false
  }

  return (
    <Dropdown
      classes="button-pure"
      button={
        <div className="button-pure">
          Ações
        </div>
      }
    >
      <MenuItem>
        <SliderButton button="Detalhes" sliderTitle="Detalhes do Certificado">
          <SliderDetails {...props} />
        </SliderButton>
      </MenuItem>

      {props.status === 'Valid' &&
        <MenuItem>
          <SliderButton button="Selo SSL" sliderTitle="Selo SSL">
            {seals[props.brand]}
          </SliderButton>
        </MenuItem>
      }

      {props.renewable ?
        <MenuItem>
          <Link to={`/certificates/renew/${props.id}`}>
            Renovar
          </Link>
        </MenuItem>
        :
        <MenuItem>
          <SliderButton button="Renovar" sliderTitle="Renovar Certificado">
            <ContextMessage
              type="error"
              message="Renovação indisponível para este certificado, entre em contato com o suporte"
            />
          </SliderButton>
        </MenuItem>
      }

      <RevokeOption order={props.order} />

      {props.certificate &&
        <MenuItem
          onClick={() =>
            window.open(`${process.env.REACT_APP_API_URL}${environment.API_VERSION}/certificate-orders/${props.id}/certificate`)
          }
        >
          <Link
            to={`${process.env.REACT_APP_API_URL}${environment.API_VERSION}/certificate-orders/${props.id}/certificate`}
            className="controls-links"
            target="_blank"
            rel="noreferrer"
            style={{ width: '100%' }}
          >
            Download do Certificado
          </Link>
        </MenuItem>
      }
    </Dropdown >
  )
}

class CertificateOrdersList extends React.Component {
  constructor(props) {
    super(props)

    this.model = {
      order: {
        name: 'Pedido',
        renderer: (name, item) => (
          <SliderButton button={name} sliderTitle="Detalhes do Certificado">
            <SliderDetails {...item} />
          </SliderButton>
        ) || '(nenhum)',
      },
      common_name: { name: 'Domínio' },
      product: { name: 'Produto' },
      created: {
        name: 'Criado em:',
        renderer: (date) => {
          const dateString = new Date(date).toLocaleDateString('pt-BR')
          const timeString = new Date(date).toLocaleTimeString('pt-BR')

          return <span>{`${dateString} às ${timeString}`}</span>
        },
      },
      expiration_date: {
        name: (
          <>
            <span>Válido até </span>
            <Help title="Informação">
              <p>Caso esse certificado tenha sido reemitido, a validade apresentada será da primeira emissão.</p>
            </Help>
          </>
        ),
        renderer: (date) => {
          if (date) {
            return <span>{`${date}`}</span>
          } else {
            return <span>-</span>
          }
        }
      },
      status: {
        name: 'Status',
        renderer: (status, item) => (
          <SliderButton button={status} sliderTitle="Validação">
            <ValidationMethod
              domains={item.domains}
              hash={{
                sha256: item.sha256,
                md5: item.md5,
                uniqueHash: item.comodo_hash,
              }}
            />
          </SliderButton>
        ),
        filterItems: CertificateStatusLabels,
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (item, obj) => <Actions {...obj} />,
      },
    }
  }

  render () {
    return (
      <div>
        <TableWrap
          model={this.model}
          source={this.props.certificates}
          accountId={this.props.accountId}
          actionFetch={this.props.fetchOrders}
          location={this.props.location}
          hasSearch
        >
          <TableWrapActions>
            <TableWrapLeft>
              Lista de Certificados
            </TableWrapLeft>
          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
}

Actions.propTypes = {
  id: PropTypes.string,
  is_revoked: PropTypes.bool,
  renewable: PropTypes.bool,
  order: PropTypes.number,
  certificate: PropTypes.string,
  status: PropTypes.string,
  brand: PropTypes.string,
}

CertificateOrdersList.propTypes = {
  location: PropTypes.object,
  fetchOrders: PropTypes.func,
  certificates: PropTypes.object,
  accountId: PropTypes.string.isRequired,
}

const mapStateToProps = ({ orders }) => ({ certificates: orders })

export default connect(mapStateToProps, { fetchOrders })(withAccountId(CertificateOrdersList))
