import React, { useState, useEffect } from 'react';
import { fetch } from '../../services/fetch'
import { useDispatch } from 'react-redux'
import { ERROR, setMessage, SUCCESS } from '../../modules/messages'
import { useForm, Controller } from 'react-hook-form'
import { Card, Loading } from '../../components'
import { Alert, Button, Box, Container, Grid, CardContent, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ContentCopy } from "@mui/icons-material";



const CsrGenerate = () => {

  const commonNameDescription = (

    <div>
      URL exata na qual será utilizado o certificado, exemplo:
      <br />
      <strong>www.exemplo.com</strong>
    </div>
  );

  const countryDescription = <>País da empresa requerente.</>;

  const stateDescription = <>Estado do país da empresa requerente.</>;

  const cityDescription = <>Cidade do estado da empresa requerente.</>;

  const organizationDescription = (
    <div>
      Razão social da empresa requerente, exemplo:
      <br />
      <strong>Exemplo LTDA, EXEMPLO S.A.</strong>
    </div>
  );

  const organizationUnitDescription = <>
    Departamento responsável pela emissão do certificado da empresa requerente.
  </>;

  const sizeDescription = <>Tamanho da chave 2048 ou 4096 Bits.</>;

  const sansDescription = (
    <div>
      Listagem em texto simples de URLs, separadas por vírgulas, exemplo:
      <br />
      <strong>www.exemplo1.com,www.exemplo2.com,www.exemplo3.com</strong>
    </div>
  );

  const csrDescription = (
    <Alert severity="warning">
      <Typography variant='h5'><strong>Não salvamos as informações geradas. Por favor, copie e guarde em um local seguro.</strong></Typography>
    </Alert>
  );

  const INFO_VALUES = {
    "common_name": commonNameDescription,
    "country": countryDescription,
    "state": stateDescription,
    "city": cityDescription,
    "organization": organizationDescription,
    "organization_unit": organizationUnitDescription,
    "size": sizeDescription,
    "sans": sansDescription,
    "csr": csrDescription
  };

  const [infoValue, setInfoValue] = useState('')
  const [load, setLoad] = useState(false)
  const [csr, setCsr] = useState(null)
  const [tooltipCsr, setTooltipCsr] = useState("Copiar");
  const [tooltipPkey, setTooltipPkey] = useState("Copiar");

  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors }, control, setValue } = useForm()

  useEffect(() => {
    document.title = 'Site Blindado - Validar CSR'
  }, [])

  const onSubmit = async (dataForm) => {
    const formData = {
      ...dataForm,
      "sans": dataForm?.sans
        ? dataForm.sans.replace(/\s+/g, '').split(',').map(s => `DNS:${s}`)
        : []
    };
    setLoad(true);
    try {
      const response = await fetch(`/csr`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        return dispatch(setMessage(ERROR, "Erro ao gerar a CSR"));
      }

      const result = await response.json();
      setCsr(result);
      dispatch(setMessage(SUCCESS, 'CSR gerada com sucesso!'));
      setInfoValue(INFO_VALUES["csr"])
    } catch (error) {
      dispatch(setMessage(ERROR, error.message));
    } finally {
      setLoad(false);
    }
  };

  const handleCopy = (value, tooltip) => {
    navigator.clipboard.writeText(value);
    tooltip === "csr" ? handleTooltipCsrChange() : handleTooltipPkeyChange()
  };

  const handleTooltipCsrChange = () => {
    setTooltipCsr("Copiado!");
    setTimeout(() => setTooltipCsr("Copiar"), 1500);
  }

  const handleTooltipPkeyChange = () => {
    setTooltipPkey("Copiado!");
    setTimeout(() => setTooltipPkey("Copiar"), 1500);
  }

  const handleChangeInfo = (infoField) => {
    setInfoValue(INFO_VALUES[infoField])
  }

  const downloadFile = (content, fileName) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <section className="content">
      <Card>
        <Container maxWidth="xl">
          <Loading loading={load} />
          <Box sx={{ padding: '4rem' }}>
            <Typography variant="h3"><strong>Gerar CSR</strong></Typography>
            <Box sx={{ paddingX: '3rem', marginTop: '2rem' }}>
              <Grid container spacing={6} alignItems="stretch">

                <Grid item xs={5}>
                  <Box sx={{ padding: '2rem', height: "100%", borderRadius: "14px", backgroundColor: "#eeeeee60" }}>

                    <Typography variant="h4"><strong>Formulário</strong></Typography>

                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <Box sx={{ marginTop: "2rem" }}>
                        <div className="field">
                          <label htmlFor="common_name">
                            Common Name *
                          </label>
                          <Controller
                            render={({ field }) =>
                              <input
                                {...field}
                                className={`input ${errors.common_name ? 'input-error' : ''}`}
                                type="text"
                                onFocus={() => handleChangeInfo("common_name")}
                              />
                            }
                            defaultValue=""
                            name="common_name"
                            rules={{
                              required: "Campo obrigatório",
                              pattern: {
                                value: /^[a-zA-Z0-9\s.,*]*$/,
                                message: "Não são permitidos caracteres especiais",
                              },
                            }}
                            control={control}
                          />
                          {errors.common_name && (
                            <Typography variant='body2' className="input-error">{errors?.common_name.message}</Typography>
                          )}
                        </div>

                        <div className="field">
                          <label htmlFor="country">
                            País *
                          </label>
                          <Controller
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`input ${errors.country ? 'input-error' : ''}`}
                                onFocus={() => handleChangeInfo("country")}
                              >
                                <option value="">Selecione um país</option>
                                <option value="BR">Brasil</option>
                                <option value="US">Estados Unidos</option>
                                <option value="DE">Alemanha</option>
                                <option value="FR">França</option>
                                <option value="IN">Índia</option>
                              </select>
                            )}
                            defaultValue=""
                            name="country"
                            rules={{ required: true }}
                            control={control}
                          />
                          {errors.country && (
                            <Typography variant='body2' className="input-error">{errors?.country.message}</Typography>
                          )}
                        </div>

                        <div className="field">
                          <label htmlFor="state">
                            Estado *
                          </label>
                          <Controller
                            render={({ field }) =>
                              <input
                                {...field}
                                className={`input ${errors.state ? 'input-error' : ''}`}
                                type="text"
                                onFocus={() => handleChangeInfo("state")}
                              />
                            }
                            defaultValue=""
                            name="state"
                            rules={{
                              required: "Campo obrigatório",
                              pattern: {
                                value: /^[a-zA-Z0-9\s.,*]*$/,
                                message: "Não são permitidos caracteres especiais",
                              },
                            }}
                            control={control}
                          />
                          {errors.state && (
                            <Typography variant='body2' className="input-error">{errors?.state.message}</Typography>
                          )}
                        </div>


                        <div className="field">
                          <label htmlFor="city">
                            Cidade *
                          </label>
                          <Controller
                            render={({ field }) =>
                              <input
                                {...field}
                                className={`input ${errors.city ? 'input-error' : ''}`}
                                type="text"
                                onFocus={() => handleChangeInfo("city")}
                              />
                            }
                            defaultValue=""
                            name="city"
                            rules={{
                              required: "Campo obrigatório",
                              pattern: {
                                value: /^[a-zA-Z0-9\s.,*]*$/,
                                message: "Não são permitidos caracteres especiais",
                              },
                            }}
                            control={control}
                          />
                          {errors.city && (
                            <Typography variant='body2' className="input-error">{errors?.city.message}</Typography>
                          )}
                        </div>
                        <div className="field">
                          <label htmlFor="organization">
                            Razão Social *
                          </label>
                          <Controller
                            render={({ field }) =>
                              <input
                                {...field}
                                className={`input ${errors.organization ? 'input-error' : ''}`}
                                type="text"
                                onFocus={() => handleChangeInfo("organization")}
                              />
                            }
                            defaultValue=""
                            name="organization"
                            rules={{
                              required: "Campo obrigatório",
                              pattern: {
                                value: /^[a-zA-Z0-9\s.,*]*$/,
                                message: "Não são permitidos caracteres especiais",
                              },
                            }}
                            control={control}
                          />
                          {errors.organization && (
                            <Typography variant='body2' className="input-error">{errors?.organization.message}</Typography>
                          )}
                        </div>
                        <div className="field">
                          <label htmlFor="organization_unit">
                            Departamento *
                          </label>
                          <Controller
                            render={({ field }) =>
                              <input
                                {...field}
                                className={`input ${errors.organization_unit ? 'input-error' : ''}`}
                                type="text"
                                onFocus={() => handleChangeInfo("organization_unit")}
                              />
                            }
                            defaultValue=""
                            name="organization_unit"
                            rules={{
                              required: "Campo obrigatório",
                              pattern: {
                                value: /^[a-zA-Z0-9\s.,*]*$/,
                                message: "Não são permitidos caracteres especiais",
                              },
                            }}
                            control={control}
                          />
                          {errors.organization_unit && (
                            <Typography variant='body2' className="input-error">{errors?.organization_unit.message}</Typography>
                          )}
                        </div>
                        <div className="field">
                          <label htmlFor="size">
                            Tamanho da chave (bits) *
                          </label>
                          <Controller
                            render={({ field }) => (
                              <Grid container spacing={2}>
                                <Grid item>
                                  <label style={{ display: "flex", alignItems: "center", margin: 0 }}>
                                    <input
                                      {...field}
                                      type="radio"
                                      value="2048"
                                      className={`${errors.size ? 'input-error' : ''}`}
                                      onFocus={() => handleChangeInfo("size")}
                                      style={{ marginRight: "5px" }}
                                    />
                                    2048
                                  </label>
                                </Grid>
                                <Grid item>
                                  <label style={{ display: "flex", alignItems: "center", margin: 0 }}>
                                    <input
                                      {...field}
                                      type="radio"
                                      value="4096"
                                      className={`${errors.size ? 'input-error' : ''}`}
                                      onFocus={() => handleChangeInfo("size")}
                                      style={{ marginRight: "5px" }}
                                    />
                                    4096
                                  </label>
                                </Grid>

                              </Grid>
                            )}
                            defaultValue=""
                            name="size"
                            rules={{ required: "Campo obrigatório", }}
                            control={control}
                          />
                          {errors.size && (
                            <Typography variant='body2' className="input-error">{errors?.size.message}</Typography>
                          )}
                        </div>
                        <div className="field">
                          <label htmlFor="sans">
                            Listagem Url's
                          </label>
                          <Controller
                            render={({ field }) =>
                              <input
                                {...field}
                                className={`input ${errors.sans ? 'input-error' : ''}`}
                                type="text"
                                onFocus={() => handleChangeInfo("sans")}
                              />
                            }
                            defaultValue=""
                            name="sans"
                            control={control}
                          />
                        </div>
                      </Box>
                      <Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Button type='submit' size="large" variant="contained" sx={{ backgroundColor: "#74B537" }}>
                          <Typography variant='h4' color={'white'}><strong>Gerar CSR</strong></Typography>
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Grid>

                <Grid item xs={7} >
                  <Grid container spacing={3}>
                    <Grid xs={12} item>
                      <Box sx={{ padding: '2rem', borderRadius: "14px", backgroundColor: "#eeeeee60" }}>
                        <Typography variant="h4"><strong>Informações</strong></Typography>
                        <Box sx={{ display: "flex", alignItems: "center", height: "5rem", marginTop: "1rem" }}>
                          {infoValue === "" ? "Selecione um campo para receber orientações" : infoValue}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid xs={12} item>
                      <Box sx={{ padding: '2rem', borderRadius: "14px", backgroundColor: "#eeeeee60" }}>
                        <Typography variant="h4"><strong>CSR</strong></Typography>
                        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                          {csr ?
                            (
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Card sx={{ p: 2, flexGrow: 1, borderRadius: "16px", minHeight: "230px" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                      <Typography variant="h6" component="pre" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxHeight: "200px", overflow: "auto" }}>
                                        {csr?.csr}
                                      </Typography>
                                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
                                        <Tooltip sx={{ marginTop: "1rem" }} title={tooltipCsr}>
                                          <IconButton onClick={() => handleCopy(csr?.csr, "csr")}>
                                            <ContentCopy />
                                          </IconButton>
                                        </Tooltip>
                                        <Button variant="outlined" sx={{ marginTop: "1rem" }} onClick={() => downloadFile(csr?.csr, "csr.pem")}>
                                          Download
                                        </Button>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Grid>

                                <Grid item xs={12}>
                                  <Typography variant="h4"><strong>CHAVE PRIVADA</strong></Typography>
                                  <Card sx={{ p: 2, flexGrow: 1, borderRadius: "16px", minHeight: "230px" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                      <Typography variant="h6" component="pre" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxHeight: "200px", overflow: "auto" }}>
                                        {csr?.csr_pkey}
                                      </Typography>
                                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
                                        <Tooltip sx={{ marginTop: "1rem" }} title={tooltipPkey}>
                                          <IconButton onClick={() => handleCopy(csr?.csr_pkey, "pkey")}>
                                            <ContentCopy />
                                          </IconButton>
                                        </Tooltip>
                                        <Button variant="outlined" sx={{ marginTop: "1rem" }} onClick={() => downloadFile(csr?.csr_pkey, "private_key.pem")}>
                                          Download
                                        </Button>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>
                            )
                            :
                            (<Typography variant="body1" sx={{ marginTop: "2rem" }}>Preencha o formulário para gerar sua CSR</Typography>)
                          }
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Card>
    </section >
  )
}

export default CsrGenerate;
