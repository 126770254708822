import React from 'react'
import { MainDashboard } from '../../containers'





export const IndexDashboard = () => {
  return <MainDashboard />;
}
