import React, { useEffect } from 'react'

import { Box, Card, CardContent, Grid, Typography, CssBaseline } from "@mui/material";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DashboardArmour, DashboardOrganizationUsersList, DashboardProducts, DashboardSSLWithoutSummaryProducts } from "./index";
import { NotificationList } from "../components/notifications/NotificationList";
import { StoreBanner } from "../components";
import { fetchSummaryProducts } from "../actions/products";
import { withAccount } from "./AccountContainer";

const MainDashboard = (props) => {

  const produtos = {
    title: "Produtos",
    component: <DashboardProducts isMainDashboard={true} />
  }

  const blindagem = {
    title: "Blindagem",
    component: (<>
      <DashboardArmour isMainDashboard={true} />
      <NavLink className="button-primary" style={{ marginLeft: '4rem' }} to={`/armour/applications`}>Mais detalhes</NavLink>
    </>)
  }

  const certificado = {
    title: "Certificados",
    component: (<>
      <DashboardSSLWithoutSummaryProducts />
      <NavLink className="button-primary" style={{ marginLeft: '4rem' }} to={`/certificates/domains`}>Mais detalhes</NavLink>
    </>)
  }

  const notificacao = {
    title: "Notificações",
    component: <NotificationList pageSizeDefault={3} />
  }

  const usuarios = {
    title: "Membros da Organização",
    component: <DashboardOrganizationUsersList />
  }

  const graphs = [produtos, notificacao, blindagem, certificado];

  return (

    <Box className="main-dashboard">
      <StoreBanner
        catalog="dashboard"
        title="Dashboard"
        description='<p style={{color: "white"}}>Tenha acesso de forma rápida aos principais modulos do Portal da Site Blindado</p>'
        height={"300px"}
      />
      <Box sx={{ p: 4 }}>
        <Grid container spacing={2}>
          {graphs.map((graph, index) => (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} key={index}>
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardContent>
                  <Typography variant="h4" sx={{ mb: 2 }}>{graph.title}</Typography>
                  <Box>
                    {graph.component}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ p: 4, mt: -5 }}>
        <Grid container>
          <Card sx={{ width: "100%", height: "100%" }}>
            <CardContent>
              <Typography variant="h4" sx={{ mb: 4 }}>{usuarios.title}</Typography>
              <Box>
                {usuarios.component}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </Box>

  );
}

MainDashboard.propTypes = {
  fetchSummaryProducts: PropTypes.func,
  account: PropTypes.object,
}

const mapStateToProps = ({ }) => ({})

export default connect(mapStateToProps, { fetchSummaryProducts })(withAccount(MainDashboard))
